import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import ExternalLink from "../components/svg/external-link.svg"

export default function Project({ data }) {

  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return <Layout>

    <Helmet>
      <title> {frontmatter.title} – Picturedigits </title>

      <meta name="description" content={frontmatter.byline} />
      <meta name="author" content="Picturedigits" />
    </Helmet>

    <article className="mt3 w-100">

      <h1 className="f2 f1-ns mb3">
        {frontmatter.title}
      </h1>

      <div className="cf dib pv2">
        {
          ['Year', 'Client', 'Link'].map(x => frontmatter[x.toLowerCase()]
            ? <div className="dib mr4 pv1">
                <h5 className="f5 ma0">{x}</h5>
                { x === 'Link' ?
                  <a href={frontmatter.link} className="link black dim">
                    {frontmatter.link.split('//')[1]}
                    <img src={ExternalLink} alt="Exernal Link" className="ml1 h1" />
                  </a>
                  : frontmatter[ x.toLowerCase() ] 
                }
              </div>
            : ''
          )
        }
      </div>

      <div
        dangerouslySetInnerHTML={{ __html: html }}>
      </div>

    </article>

  </Layout>
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        slug
        year
        client
        link
        byline
      }
    }
  }
`